.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 40px;
}
.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-size: calc(10px + 2vmin);
  color: #000;
  flex-shrink: 0;
}
main {
  flex-grow: 1;
    overflow: auto;
    min-height: 2em;
    display: flex;
    z-index: 6;
}
.logo {
  z-index: 4;
}
.logo svg{
  height: 10vmin;
  padding: 15px;
}
body.dark .logo svg {
  fill: #fff;
}
.navigation-menu ul {
  list-style: none;
  margin: 0;
  display: inline-flex;
}
.navigation-menu {
  margin-left: auto;
}
.navigation-menu ul li a{
  float: left;
  margin-right: 60px;
  text-transform: uppercase;
  font-size: 0;
  font-weight: 900;
  color: #c8c8c8;
  width: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.navigation-menu ul li a {
  text-decoration: none;
  color: #c8c8c8;
}

.navigation-menu ul li:last-child a{
  margin-right: 0;
}
.navigation-menu ul li.active a,
.navigation-menu ul li:hover a {
  color: #000;
}
.navigation-menu ul li a.active,
.navigation-menu ul li:hover a {
  color: #000;
  font-size: 28px;
  width: auto;
}
body.dark .navigation-menu ul li a.active,
body.dark .navigation-menu ul li:hover a{
  color: #fff;
}
.navigation-menu ul li a::first-letter {
  font-size: 28px;
}
.App-link {
  color: #000;
}
a.mode {
  cursor: pointer;
}
footer {
  margin-bottom: 0;
  display: flex;
  flex-shrink: 0;
  padding: 10px 0;
}
footer .col {
  flex: 1;
}
footer .col {
  font-size: 14px;
  color: #c8c8c8;
  text-align: center;
}
footer .col.middle div {
  width: 33%;
  height: 26px;
}
footer .col.middle .copy {
  float: left;
  margin-top: 3px;
  text-align: right;
  color: #000;
}
footer .col.middle .copy p {
  margin:0;
}
footer .col.middle .privacy {
  float: right;
  margin-top: 3px;
  text-align: left;
}
footer .col.middle .privacy a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}
body.dark footer .col.middle .privacy a,
body.dark footer .col.middle .copy,
body.dark .languages a {
  color: #c8c8c8;
}
body.dark footer .col.middle .privacy a:hover,
body.dark footer .col.middle .copy:hover,
body.dark .languages a:hover {
  color: #fff;
}
footer .col:first-child {
  text-align: left;
  cursor: pointer;
}
footer .col:last-child {
  text-align: right;
}
.info {
  cursor: pointer;
  width: 26px;
  height: 26px;
  margin: 0 auto;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAQAAAADQ4RFAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfkARENIiLuzn7pAAABZUlEQVQ4y52UwXmDMAxGX1gg3qB0Arj5GDpBs0EYgREYgWxANkgnKL3JN5igZAOYQD1AUkxIk1Q+Sd//LMmfpZXim9uyJeFldE9UHO3R16ymkEvJL/KpnchtuQA5Q8k7AA0l9RiOSYkA+CC1nQc5Q0UEfJHbalZwQs4GaEhGTFEUqUVFJVeWj+SiolIP3hAqREUl9WSllJ6fiopKMUISiorOJMWvZHKNikqoBEAO9GReIyU9PaUXy+hHtZjlbiSWeLkzZSUJn8CrbblrLuQbeAtIgOYaccaZecy2NEASANBdI1RU19igvAERExERL1YZDpDhGWufhcxQXg1ELnyEcCERUAVUAKQP5RlUdWA7DkC28FLzPIYMONju/I3WFHfzFKwHdQC2ZQ/s3LTElp6edpInZQfsbcvtefL/nj9P/5rc6Y44sgHOO6IDzJ874lL3c9toAm5JWI9uT8VxCixCFziEWzP2A1+iCCgBU8s2AAAAAElFTkSuQmCC");
}
.light {
  width: 32px;
  height: 31px;
  display: inline-block;
  background-image: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAfCAQAAAAsXwcHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfjDBUSBRn4jaK7AAABsElEQVQ4y52VwZmbMBBGn/NxtzqwO4hz0tF0sO5gcQfeCkIqCKkgSgfeCgI3z21L8HaAK2APCHkQAmfz83GQNPM0M9IAHUvPxVzcxSxZZCzL8YThMG/w5QHA+Pe/AQ/1CND69zFAcumkkyJaL/hDNCdOOqmHUVzE34J196Ftp+486/GqmyzZlR8fyNkBUHO2b32c/AXgOGyjAAPCrkAKSjajrRtKW3vA8R7lCABSgHVxmEEvOArqPpokYJRlQ0VtW9mSh3iOukJJgJz4mTIN2G96/2kKhivr1E4B0dhcz8YX6cAaeJ26Ayfegb1slwEAZaqCtqVSNl6ZGBwGaClsyxZgnKVSP7/VPhkVT365ouAr0My4Y2sB2GmfjBPG007AOxt/+xKSfuWqfTLbjnK6smEtu5kkPED7xEU8+3qnVSqbGYDjBjxLnkigv43NOLoIEI7qPD5tkAPfVRRBqV54689C37hwQ3/ZKL3UJy3nBuxFmzrWQBO7JyMIDXXjnu0emDTSLACk9i5aP2w5tZwDGCrGZaxT7rOAf9fCry20zNBonwWolukb7dOAoWWGRkvqAxxKvIjfdlNWAAAAAElFTkSuQmCC");
}
.light:hover,
.light:focus {
  background-image: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAfCAQAAAAsXwcHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfkAREOGxOz0k0wAAABNUlEQVQ4y7WUYXWEMBCEP/pOQBwUCa2CIgEJSIgEHBwSkICESDgJ1AFVkP4g4XKQ7HLX18njB+zuZHayBGQYRgx/wIRnkhLeVAXICjQCFRrBEp4TaPB4ukMLRxNHPK5EcKQgU54liCG/vbcMOByOno9z24wbQcccUuNyNMVGE3R0iZb9shjspkbp0uNoMUCd6NEcAmwhNdIq+xuW4k6C/6kHvjj9JjRSSwSTKNMGKxNctllb6FgC+61AsH6vH2qSIxtB7XKNJzUXLCawWeCbd8HnNTLvah7gRA9Wi3vJRLs1k8OsT0KcgyYT68/MQUxbDqfdBtsajQBumZ2iskEvvyenDk8l+VXByivwkwzUFwCfxRE7wGVugz6XWBUIDMPORief/8uohNj9Sl9/tKeR3ozjvyn4Bd/TvXO2OKmNAAAAAElFTkSuQmCC");
}
.dark .light:hover,
.dark .light:focus {
  background-image: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAfCAQAAAAsXwcHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfjDBUSBRn4jaK7AAABsElEQVQ4y52VwZmbMBBGn/NxtzqwO4hz0tF0sO5gcQfeCkIqCKkgSgfeCgI3z21L8HaAK2APCHkQAmfz83GQNPM0M9IAHUvPxVzcxSxZZCzL8YThMG/w5QHA+Pe/AQ/1CND69zFAcumkkyJaL/hDNCdOOqmHUVzE34J196Ftp+486/GqmyzZlR8fyNkBUHO2b32c/AXgOGyjAAPCrkAKSjajrRtKW3vA8R7lCABSgHVxmEEvOArqPpokYJRlQ0VtW9mSh3iOukJJgJz4mTIN2G96/2kKhivr1E4B0dhcz8YX6cAaeJ26Ayfegb1slwEAZaqCtqVSNl6ZGBwGaClsyxZgnKVSP7/VPhkVT365ouAr0My4Y2sB2GmfjBPG007AOxt/+xKSfuWqfTLbjnK6smEtu5kkPED7xEU8+3qnVSqbGYDjBjxLnkigv43NOLoIEI7qPD5tkAPfVRRBqV54689C37hwQ3/ZKL3UJy3nBuxFmzrWQBO7JyMIDXXjnu0emDTSLACk9i5aP2w5tZwDGCrGZaxT7rOAf9fCry20zNBonwWolukb7dOAoWWGRkvqAxxKvIjfdlNWAAAAAElFTkSuQmCC");
}
.language {
  float: left;
  width: 26px;
  height: 26px;
  background-repeat: no-repeat;
  background-image: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAQAAACBIibWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfkARENIxw2tFIDAAABf0lEQVQ4y5XUP3bTQBAG8J9c8WjkG0ScwH4p2DKiotUNUG4QOkqXdPgI5gZqqXC6XSr5BCg3iBoeFUth4fzBkHi2mtn9ZmZn5psieyipUqtVGGxtw/DwvrgPSK0ri0cedtZhcwSQKhsXB/tPvDho19o/kSZAWtoqce2XN0ZL9EpfzVxgVIceZveejy5D7RzrMITBGuehdmlU2qblBEhzndKoDpvUKLHPeYMyNWGjNip1ab6PsHKGJvRYYrfPNwx2e0voNTizooiV7/g8eV1b+OLj9NkP3tq5Aq13eFXEK588X97PNCc8pxFzzLHN9ifmmGN90OqYYz5obcwxz8DwLO/DoQ+P5PZ/qGOA+dOA6lkpVVDE7b2Re1quZ7pTqqo7udOyuL6rflzFHPtD5fuY4+quI3Gdp+G7QZeW6LFIFaTKYm9JSx1urJgRbjXTxDehM6KdkmAMXWontjTh9t+Mq8yPM+4Yp7PCD7yUFfti/sXpCXTK1nAXqVZ7jW/H9tJvKfnEG+aKEJAAAAAASUVORK5CYII=");
}
.language:hover,
.language:focus,
.language.actived {
  background-image: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAQAAACBIibWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfkAREOGTrDVrfeAAABJklEQVQ4y5XUu1HDQBAG4M8eCnAJ6gBHxFeCOkAdQAkuwSWYDggdeRQTqQSFhEoYMpZApwfGCHs32bu9f+e/fa2cSyFJCrRqdbQWpNKIM21U8+M8cj27//Q5O9WKc8BWl10noVModMIph+ls54D+eadCJ+zATuiCKnu3A2Cj7S9QCqHIJEMoYwjY2vSAvRDSGLUZiTbCLgRJCPtwp/CEFySUeM9g3t0rVzV48ehptef5VyKX9NmPZP6vNSFUI+vpN2TmYwUqIdZgsfyjtLC+4OiWUJcAm/8BxVWU8qsbs7T2elX0QV6HjjlIkqQRjtlOjkKT7cPUZTf1Up+VG7t1mofSVfMwQaaJ2yxPXE9gSPCX8OEjW3/MdC9XbI3VhXomyQPeLu2lbzqIK56v6U1wAAAAAElFTkSuQmCC");
}
.dark .language:hover,
.dark .language:focus,
.dark .language.actived {
  background-image: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAQAAACBIibWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfkARENIxw2tFIDAAABf0lEQVQ4y5XUP3bTQBAG8J9c8WjkG0ScwH4p2DKiotUNUG4QOkqXdPgI5gZqqXC6XSr5BCg3iBoeFUth4fzBkHi2mtn9ZmZn5psieyipUqtVGGxtw/DwvrgPSK0ri0cedtZhcwSQKhsXB/tPvDho19o/kSZAWtoqce2XN0ZL9EpfzVxgVIceZveejy5D7RzrMITBGuehdmlU2qblBEhzndKoDpvUKLHPeYMyNWGjNip1ab6PsHKGJvRYYrfPNwx2e0voNTizooiV7/g8eV1b+OLj9NkP3tq5Aq13eFXEK588X97PNCc8pxFzzLHN9ifmmGN90OqYYz5obcwxz8DwLO/DoQ+P5PZ/qGOA+dOA6lkpVVDE7b2Re1quZ7pTqqo7udOyuL6rflzFHPtD5fuY4+quI3Gdp+G7QZeW6LFIFaTKYm9JSx1urJgRbjXTxDehM6KdkmAMXWontjTh9t+Mq8yPM+4Yp7PCD7yUFfti/sXpCXTK1nAXqVZ7jW/H9tJvKfnEG+aKEJAAAAAASUVORK5CYII=");
}
.languages {
  float: left;
  margin-left: 10px;
  margin-top: 5px;
}
.languages a {
  margin-right: 5px;
  text-decoration: none;
  color: #000;
}
.info:hover,
.info:focus,
.info.actived {
  background-image: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAQAAAADQ4RFAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfkAREOGTrDVrfeAAABMElEQVQ4y53UQWGEMBCF4a+rAAk4AAlx0EpAQiVUwkpAwtYBElgHSIiD6SHslrBwaF9OkPknM8lL2OvDaBHrWIw+Qj1qDZvw7VgM28+3J9AYvYO70bz+7Q068G2IXK/RmIUwSfVEkExCmDV1eQX5eunw0cVXwbbQVQhDFT0af6FgEML1AbVClJCnSpqr7Z6NQmhjzRmypoJ6WdZXUCMLY6A56abXb8v77SyQyqJOVEGlkXSRcLe8RDe7ghGLO9IF5ANkMr1iJfIM6nW60tWL2sua9y9a/go1pbwZ3fnubfXW6jBdTLCz0JlK1MyxI8rppTNHHHvvCKq8d+TyVpa15y4vde4dWHnv6D798+YW48Q6+Snp9ZLPNVm4PZB/vkZHZ3GTn/PZrQbqJ2yvFovDvD/w6gcjVefvuwAAAABJRU5ErkJggg==");
}
.dark .info:hover,
.dark .info:focus,
.dark .info.actived {
  background-image: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAQAAAADQ4RFAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfkARENIiLuzn7pAAABZUlEQVQ4y52UwXmDMAxGX1gg3qB0Arj5GDpBs0EYgREYgWxANkgnKL3JN5igZAOYQD1AUkxIk1Q+Sd//LMmfpZXim9uyJeFldE9UHO3R16ymkEvJL/KpnchtuQA5Q8k7AA0l9RiOSYkA+CC1nQc5Q0UEfJHbalZwQs4GaEhGTFEUqUVFJVeWj+SiolIP3hAqREUl9WSllJ6fiopKMUISiorOJMWvZHKNikqoBEAO9GReIyU9PaUXy+hHtZjlbiSWeLkzZSUJn8CrbblrLuQbeAtIgOYaccaZecy2NEASANBdI1RU19igvAERExERL1YZDpDhGWufhcxQXg1ELnyEcCERUAVUAKQP5RlUdWA7DkC28FLzPIYMONju/I3WFHfzFKwHdQC2ZQ/s3LTElp6edpInZQfsbcvtefL/nj9P/5rc6Y44sgHOO6IDzJ874lL3c9toAm5JWI9uT8VxCixCFziEWzP2A1+iCCgBU8s2AAAAAElFTkSuQmCC");
}
.rotate {
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    -o-transition-duration: 0.8s;
    transition-duration: 0.8s;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
     transition-property: transform;
     overflow:hidden;
}
.rotate:hover,
.rotate:focus {
	  transform:rotate(360deg);
}
.modal-wrapper {
  background: white;
  border: 1px solid #d0cccc;
  box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
  margin: 100px auto 0;
  transition: all .8s;
  width: 500px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 30%;
  z-index: 2;
}
body.dark .modal-wrapper {
  background-color: #000;
  border: 0;
}
.modal-header {
  height: 30px;
  line-height: 30px;
  padding: 5px 10px;
  text-align: right;
}

.modal-header h3 {
  color: white;
  float: left;
  margin: 0;
  padding: 0;
}

.modal-body {
  /* padding: 10px 15px; */
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;;
}

.modal-logo {
  display: block;
  clear: both;
  width: 100%;
}

.modal-client,
.modal-tech,
.modal-desc,
.modal-goal {
  margin: 10px 0;
  font-weight: 500;
}

.modal-client span,
.modal-tech span,
.modal-desc span,
.modal-goal span {
  color: #c8c8c8;
  font-weight: 800;
}

body.dark .modal-client span,
body.dark .modal-tech span,
body.dark .modal-desc span,
body.dark .modal-goal span {
  color: #373737;
  font-weight: 800;
}

.modal-logo img {
  height: 100px;
  margin: 0;
  padding: 0;
  opacity: 1 !important;
}

.modal-footer {
  background: #263238;
  height: 35px;
  padding: 15px;
}

.close-modal-btn {
  color: #000;
  cursor: pointer;
  float: right;
  font-size: 35px;
  margin: 0;
}

.close-modal-btn:hover {
  color: black;
}
body.dark .close-modal-btn,
body.dark .close-modal-btn:hover {
  color: #fff;
}
.btn-cancel, .btn-continue {
  background: coral;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  padding: 10px;
}

.btn-cancel {
  background-color: #b71c1c;
  float: left;
}

.btn-continue {
  background-color: #1b5e20;
  float: right;
}

.back-drop {
  background-color: rgba(48, 49, 48, 0.42);
  height: 100%;
  transition: all 1.3s;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.open-modal-btn {
  margin: 15px;
  padding: 10px;
  font-weight: bold;
}

.flex-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex-grid .col {
  flex: 1;
  flex-grow: 1;
  flex-basis: 20%;
  margin: 0 0 20px 0;
}
.contact, .services {
  width: 100%;
}
.work img {
  opacity: 0.3;
  cursor: pointer;
  transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
}

.work img:hover {
  opacity: 1;
}

#responsive-form{
	max-width:560px; 
	margin:0 auto;
  width:100%;
  padding: 20px 0 20px 0;

}
.form-row{
	width: 100%;

}
.column-full{ 
  float: left;
  position: relative;
  padding: 0.65rem;
  width:100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.column-half{
	float: left;
	position: relative;
	padding: 0.65rem;
	width:50%;
	-webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.clearfix:after {
	content: "";
	display: table;
	clear: both;
}

.webform-button--submit {
    font-size: 18px;
    font-weight: 500;
    width: 100px;
    height: 42.67px;
    color: #fff;
    background: #000;
    border: 0;
    margin: 0 auto;
    display: block;
    cursor: pointer;
}

::-moz-placeholder {
  opacity: 1;
}

#responsive-form input {
	height: 30px;
}

#responsive-form input {
	width: 100%;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 0;
	border-bottom: 1px solid #000;
  font-size: 18px;
  color: #c8c8c8 !important;
  float: left;
  font-weight: 500;
}
#responsive-form label {
  padding: 6px 0;
  font-size: 18px;
  font-weight: 500;
  color: #c8c8c8 !important;
  display: block;
}
#responsive-form textarea {
  width: 100%;
  padding: 8px;
  resize: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
  box-sizing: border-box;
	border: 1px solid #000;
  font-size: 14px;
  font-weight: 700;
  color: #c8c8c8 !important;
}

body.dark #responsive-form input,
body.dark #responsive-form textarea {
  border-color: #fff;
  background-color: transparent;
}

body.dark .webform-button--submit {
  background-color: #fff;
  color: #000;
}
svg.svg-inline--fa {
  padding: 4px;
  border: 2px solid #000;
  border-radius: 50%;
  width: 0.75em !important;
  height: 0.75em;
  float: left;
}
.column-half span {
  line-height: 20px;
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
  float: left;
  min-height: 40px;
}
a.contact-phone {
  color: #000;
  display: flex;
}
body.dark a.contact-phone {
  color: #fff;
}

img.white {
  display: none;
}

body.dark img.black {
  display: none;
}

body.dark img.white {
  display: block;
}
.rwt__tabs,
.plain-text {
  margin-top: 105px;
  margin-bottom: 125px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]::after {
  display: none;
}
.rwt__tablist[aria-orientation="vertical"] {
  border: 0;
  margin: 0;
  width: 420px;
}
.rwt__tablist button {
  font-weight: 900;
  text-transform: uppercase;
  text-align: right;
  font-size: 35px;
  color: #c8c8c8;
  cursor: pointer;
  padding: 0.5rem 2rem;
}


body.dark .rwt__tablist button:hover,
body.dark .rwt__tab[aria-selected="true"] {
  color: #fff;
}
.rwt__tablist button:hover {
  color: #000;
  background: transparent !important;
}
.rwt__tab[aria-selected="true"] {
  font-size: 90px;
  color: #000;
  line-height: 95px;
  max-width: 530px;
  word-wrap: break-word;
  padding-left: 0;
}
.iXUMqo,
.kGcEiU {
  overflow: auto !important;
}

.burger-menu,
.burger-menu.open {
  display: inline-block;
  cursor: pointer;
  position: fixed;
  right: 20px;
  top: 10px;
  z-index: 9999;
  padding: 10px;
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.9) none repeat scroll 0% 0% !important;
}
.popup-content {
  background-color: transparent !important;
  border: 0 !important;
}
.burger-menu .bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 5px;
  background-color: #000;
  margin: 4px 0;
  transition: 0.4s;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

body.dark .burger-menu .bar1,
body.dark .bar2,
body.dark .bar3,
body.dark .burger-menu.open .bar1,
body.dark .burger-menu.open .bar3 {
  background-color: #fff;
} 

.burger-menu.open .bar1 {
  background-color: #c8c8c8;
  -webkit-transform: rotate(-45deg) translate(-7px, 6px);
  transform: rotate(-45deg) translate(-7px, 6px);
  -moz-transform: rotate(-45deg) translate(-7px, 6px);
  -ms-transform: rotate(-45deg) translate(-7px, 6px);
  -o-transform: rotate(-45deg) translate(-7px, 6px);
}

.burger-menu.open .bar2 {
  opacity: 0;
}

.burger-menu.open .bar3 {
  background-color: #c8c8c8;
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
}

/* Menu */

.menu {
  width: 100%;
  display: block;
  text-align: center;
  padding: 0px;
}
.menu ul {
  position: relative;
  top: 0px;
  font-size: 40px;
  padding: 0px;
  list-style: none;
  text-align: left;
}
.menu li {
  margin: 15px 0px;
  font-family: 'Montserrat', sans-serif !important;
}
.menu li a{
  text-transform: uppercase;
  padding: 0;
  cursor: pointer;
  font-weight: 900;
  color: #c8c8c8;
  text-decoration: none;
}
.menu li a:hover {
  color: #000;
}
body.dark .menu li a:hover {
  color: #fff;
}
.opacity-drop {
  background: rgba(0, 0, 0, 0.8);
}
.mobile-only {
  display: none;
}
.home {
  font-size: 35px;
  color: #c8c8c8;
  font-weight: 900;
  text-transform: uppercase;
  display: block;
  text-align: center;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  line-height: 90px;
}
.home span {
  vertical-align: middle;
  display: inline-block;
}
.home a {
  text-decoration: none;
  color: #000;
  font-size: 90px;
}
body.dark .home a {
  color: #fff;
}
body.dark svg.svg-inline--fa {
  border-color: #fff;
}
.about {
  line-height: 30px;
}
.about span {
  font-size: 50px;
  font-weight: 900;
}
.error {
  clear: both;
  font-weight: 500;
}
.error span {
  margin-left: 10px;
  line-height: 25px;
}
/**---------------- Media query ----------------**/
@media only screen and (max-width: 799px) { 
	
  .navigation-menu {
    display: none;
  }
  .logo {
    position: absolute;
    top: 0;
    left: 0;
  }
  .logo svg {
    height: 10vmin;
  }
  .App-header {
    min-height: 20vmin;
  }
  .rwt__tablist[aria-orientation="vertical"] {
    width: 330px;
  }
  .rwt__tab[aria-selected="true"] {
    font-size: 70px;
  }
  .popup-content {
    width: auto !important;
  }
  
}
@media only screen and (max-width: 415px) {
  .middle {
    display: none;
  }
  footer .col.middle .privacy {
    float: none;
    text-align: center;
    width: auto;
  }
  .wrapper {
    margin: 0 20px;
  }
  .home {
    line-height: 50px;
  }
  .home a {
    font-size: 50px;
  }
  .column-half{
		width: 100%;
  }
  .column-half span {
    min-height: auto;
  }
  .modal-wrapper {
    width: 100%;
    top: 0;
    height: 100%;
    border: 0;
    z-index: 99999;
  }
  .modal-body {
    top: 20%;
    position: relative;
  }
  .mobile-only {
    display: block;
  }
  .rwt__tabs {
    display: none !important;
  }
  .mobile-only .react-sanfona-item-title{
    font-weight: 900;
    text-transform: uppercase;
    text-align: left;
    font-size: 35px;
    color: #c8c8c8;
    cursor: pointer;
  }
  .react-sanfona-item-title[aria-expanded="true"] {
    color: #000;
  }
  body.dark .react-sanfona-item-title[aria-expanded="true"] {
    color: #fff;
  }
  .close-modal-btn {
    font-size: 55px;
  }
  .flex-grid .col {
    text-align: center;
  }
  .work img {
    margin: 0 auto;
  }
  .plain-text {
    margin-top: 20px;
  }
  a.contact-phone {
    min-height: 50px;
  }
}
@media only screen and (min-width: 800px) { 
  .burger-menu {
    display: none;
  }
}
